<template>
    <div class="temporary-drawn-number"
         :class="{retail: retail}">
      <div class="temporary-drawn-number-warpper">
        <div class="temporary-drawn-number-first">
          <div class="temporary-drawn-number-second">
            <div class="temporary-drawn-number-second-split">
              <div class="split-child"
                   v-for="(numb, index) in maxRegular"
                   :key="index"
                   :style="{
                     backgroundColor: drawnResults && drawnResults.length >= index + 1
                     ? '#2D2D2D' : primary,
                     transform: `rotate(${index * (360 / maxRegular)}deg)
                     skewY(${-90 + (360 / maxRegular)}deg)`,
                     }"></div>
            </div>
            <div class="temporary-drawn-number-third">
              <DrawnNumberSpinner :bg-color="'#000'"
                                  :spinner-color="secondary"
                                  :show="!!tempDrawnNumber"/>
              <div class="temporary-drawn-number-four">
                <div class="glow"></div>
                <div class="info-wrapper">
                  <div>
                    <h4>{{categoryShortName}}</h4>
                    <h5>{{tournamentShortName}}</h5>
                  </div>
                  <transition name="fade">
                    <div class="drawn-numb"
                         v-if="tempDrawnNumber">
                      {{tempDrawnNumber}}
                    </div>
                  </transition>
                  <div class="display-id-wrapper"
                       v-if="retail">
                    <!-- eslint-disable-next-line max-len -->
                    {{ `${$store.getters.translate('draw')} ${lotteryDisplayId}` }}
                  </div>
                  <img class="flag"
                       :src="require(`../assets/images/flags/${category}.png`)"
                       :alt="category">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="title"
         :class="{'show' : !retail || showTitle}">
        {{`${$store.getters.translate('displayingDrawFrom')} ${callFormatDate()}
${$store.getters.translate('h')}`}}
      </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/utility';
import DrawnNumberSpinner from '@/components/DrawnNumberSpinner';
import EventBus from '@/store/EventBus';

export default {
  name: 'TemporaryDrawnNumber',
  components: { DrawnNumberSpinner },
  data() {
    return {
      showTitle: true,
    };
  },
  props: {
    tempDrawnNumber: {
      type: Number,
    },
    retail: Boolean,
  },
  computed: {
    ...mapGetters([
      'maxRegular',
      'drawnResults',
      'primary',
      'eventTime',
      'category',
      'tournament',
      'flag',
      'secondary',
      'categoryShortName',
      'tournamentShortName',
    ]),
    lotteryDisplayId() {
      return this.$store.getters.retailDrawEvent?.lotteryDisplayId;
    },
  },
  methods: {
    callFormatDate() {
      return this.eventTime ? formatDate(
        this.eventTime,
        'HH:mm',
        this.$store.getters.timeZone,
      ) : '';
    },
  },
  mounted() {
    EventBus.$on('LotteryDrawTitle', (status) => {
      this.showTitle = status;
    });
  },
};
</script>

<style lang="scss" scoped>
  .temporary-drawn-number {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 730px) {
      height: 100%;
    }
    &.retail {
      height: 100%;
      .temporary-drawn-number-first {
        height: 460px;
        width: 460px;
        @media (min-width: 1920px) {
          height: 660px;
          width: 660px;
        }
        .temporary-drawn-number-third {
          .glow {
            filter: blur(22.78px) !important;
            height: 150px !important;
            opacity: 0.3 !important;
            top: 38% !important;
            width: 240px !important;
            @media (min-width: 1920px) {
              filter: blur(33.78px) !important;
              height: 250px !important;
              width: 300px !important;
            }
          }
        }
        .temporary-drawn-number-four {
          .info-wrapper {
            padding-top: 23px !important;
            h4 {
              font-size: 24px;
              margin-bottom: 4px;
            }
            h5 {
              font-size: 16px !important;
              font-weight: 400;
            }
            .display-id-wrapper {
              bottom: 63px;
              font-size: 16px;
              position: absolute;
            }
            .flag {
              border-radius: 5px;
              height: 41px !important;
              width: 62px !important;
            }
            @media (min-width: 1920px) {
              padding-top: 32px !important;
              h4 {
                font-size: 36px !important;
                margin-bottom: 2px;
              }
              h5 {
                font-size: 24px !important;
              }
              .display-id-wrapper {
                bottom: 90px !important;
                font-size: 24px !important;
              }
              .flag {
                border-radius: 8px !important;
                bottom: 20px !important;
                height: 60px !important;
                width: 90px !important;
              }
            }
          }
        }
        .drawn-numb {
          font-size: 120px !important;
          @media (min-width: 1920px) {
            font-size: 180px !important;
          }
        }
      }
      .title {
        font-size: 20px;
      }
    }
    .title {
      color: #898989;
      opacity: 0;
      text-align: center;
      transition: opacity 1s cubic-bezier(.46,.08,.12,1);
      &.show {
        opacity: 1;
      }
    }
  }
  .temporary-drawn-number-warpper {
    .temporary-drawn-number-first,
    .temporary-drawn-number-second,
    .temporary-drawn-number-third,
    .temporary-drawn-number-four {
      border-radius: 50%;
    }
  }
  .temporary-drawn-number-first {
    align-items: center;
    background: #242424;
    box-shadow: 0 0 19px 13px rgba(0, 0, 0, 0.4);
    display: flex;
    height: 162px;
    justify-content: center;
    width: 162px;
    @media screen and (min-width: 730px) {
      height: 210px;
      width: 210px;
    }
    .temporary-drawn-number-second {
      background-color: #2D2D2D;
      border: 1px solid rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 19px 13px rgba(0, 0, 0, 0.4);
      height: 94%;
      overflow: hidden;
      position: relative;
      width: 95%;
      .temporary-drawn-number-second-split {
        height: 100%;
        width: 100%;
        .split-child {
          border: 1px solid rgba(0, 0, 0, 0.25);
          height: 50%;
          position: absolute;
          right: 0;
          top: 0;
          transform-origin: 0 100%;
          transition: background-color 1s linear;
          width: 50%;
          &:first-child {
            transform: rotate(0deg) skewY(-72deg);
          }
        }
      }
      .temporary-drawn-number-third {
        background-color: #000;
        border: 2px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.5);
        height: 73%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 73%;
        .temporary-drawn-number-four {
          height: 90%;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          background-image: linear-gradient(180deg,
            rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
          width: 90%;
          .glow {
            background-color: #0B5EAF;
            border-radius: 100%;
            filter: blur(14.78px);
            height: 63px;
            opacity: 0.5;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 96px;
          }
          .info-wrapper {
            align-items: center;
            color: #EFEFEF;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
            padding: 10px;
            position: relative;
            text-align: center;
            h4 {
              text-transform: uppercase;
            }
            h5 {
              font-size: 8px;
            }
            .drawn-numb {
              font-size: 58px;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .flag {
              bottom: 10px;
              position: absolute;
              height: 19px;
              width: 28px;
            }
            @media screen and (max-width: 729px){
              padding: 7px;
              h4 {
                font-size: 9px !important;
              }
              h5 {
                font-size: 6px !important;
              }
              .drawn-numb {
                font-size: 44px !important;
              }
              .flag {
                bottom: 7px;
                height: 15px !important;
                width: 22px !important;
              }
            }
          }
        }
      }
    }
  }
</style>
