<template>
  <div class="drawn-numbers-box"
       :class="{ retail: retail }"
       :style="{justifyContent: bonus ? 'space-between' : 'center'}">
    <DrawnNumbers :numbers="drawnResults || []"
                  :draw-starting="drawStarting"
                  :is-retail="retail"
                  :max="maxRegular"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DrawnNumbers from '@/components/DrawnNumbers';

export default {
  name: 'DrawnNumbersBox',
  props: {
    drawStarting: {
      type: Boolean,
      default: false,
    },
    retail: Boolean,
  },
  components: { DrawnNumbers },
  computed: {
    ...mapGetters([
      'regular',
      'bonus',
      'drawnResults',
      'maxBonus',
      'maxRegular',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  .drawn-numbers-box {
    background-color: rgba(45, 45, 45, 0.8);
    border-radius: 8px;
    padding: 4px;
    &.retail {
      height: 152px;
      padding: 18px;
      width: 664px;
      @media (min-width: 1920px) {
        height: 228px;
        padding: 26px;
        width: 1012px;
      }
    }
    @media screen and (min-width: 730px) {
      display: flex;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
      flex-direction: column;
      height: 234px;
      padding: 8px;
      width: 191px;
    }
  }
</style>
